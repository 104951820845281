import React from 'react';
import { Container, Row, Col, CardBody } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import howitworksData from './HowItWorks.json';

function HowItWorks() {
  return (
    <Card>
    <CardBody>
    <div className="howitworks-section">
      <h1 className="howitworks-title text-center">How it Works</h1>
      {howitworksData.map((item, index) => (
        <React.Fragment key={item.label}>
          {index % 2 === 0 ? (
            // Even index, image first on desktop, normal order on mobile
            <Card style={{ marginTop: '20px' }} >
            <CardBody className='shadow-lg'>
            <Row>
              <Col md={6} xs={12} className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                <img src={`/${item.image}`} alt={item.label} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
              </Col>
              <Col md={6} xs={12} className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                <div className="text-center" style={{ width: '100%' }}>
                  <h3>{howitworksData[index + 1].title}</h3>
                  <p>{howitworksData[index + 1].explanation}</p>
                </div>
              </Col>
            </Row>
            </CardBody>
            </Card>
          ) : (
            // Odd index, text first on desktop, skip rendering as it's handled by the even index
            index % 2 === 1 && null
          )}
        </React.Fragment>
      ))}
    </div>
    </CardBody>
    </Card>

  );
}

export default HowItWorks;
