// StepTwo.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, ProgressBar } from 'react-bootstrap';
import './StepOne.css';
import PreviewRegistryModal from './PreviewRegistryModal'; // Import the modal component

const StepTwo = ({ formData, setFormData, userState, setUserState }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhotoUpload = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      photo: event.target.files[0],
    }));
  };

  const handleBack = () => {
    navigate('/create-registry'); // Go back to StepOne
  };

  const handlePreview = (event) => {
    event.preventDefault();
    const form = event.currentTarget.form;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setShowModal(true);
    }

    form.classList.add('was-validated');
  };

  const handleCreate = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.stopPropagation();
    } else {
        // Send data to backend
        try {
            const response = await fetch('http://localhost:3001/create-registry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    guid: userState.guid, // Ensure this is passed down from App.js
                    registryName: formData.registryName,
                    occasion: formData.occasion,
                    description: formData.description,
                    photo: formData.photo, // Make sure to handle file uploads appropriately
                }),
            });

            if (response.ok) {
                navigate('/create-registry-step-three'); // Navigate to StepThree
            } else {
                const errorData = await response.json();
                alert(`Failed to create registry: ${errorData.message}`);
            }
        } catch (error) {
            alert('Failed to create registry. Please try again.');
        }
    }

    form.classList.add('was-validated');
};


  return (
    <Container className="wizard-container">
      <Row className="step-indicator">
        <Col>
          <div className="step-number">Step 2 of 3</div>
          <ProgressBar now={66} className="step-progress" />
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="my-4 registry-title">TrustFund Registry</h2>
        </Col>
      </Row>

      <Form noValidate onSubmit={handleCreate}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="registryName">
            <Form.Label>Give your registry a name</Form.Label>
            <Form.Control
              type="text"
              name="registryName"
              placeholder="Olivia's 1st Birthday or Jessica Smith's Baby Shower"
              value={formData.registryName}
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a registry name.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="occasion">
            <Form.Label>Choose ocassion*</Form.Label>
            <Form.Select
              name="occasion"
              value={formData.occasion}
              onChange={handleInputChange}
              required
            >
              <option>Birthday</option>
              {/* Add more occasion options here */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select an occasion.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="description">
            <Form.Label>Tell your family and friend's about the special occasion</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
            />
            <Form.Text className="text-muted">
              Ex: Sarah's first big milestone, we're embracing a new way to mark the occasion. Instead of traditional gifts, we're inviting you to contribute to her future through cryptocurrency, a gift that has the potential to grow as she does.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please provide a description.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="photo">
            <Form.Label>Upload photo (optional)</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handlePhotoUpload}
            />
          </Form.Group>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-between">
            <button type="button" className="btn btn-outline-success" onClick={handleBack}>
              Back
            </button>
            <div>
              <button type="button" className="btn btn-outline-success me-2" onClick={handlePreview}>
                Preview registry
              </button>
              <button type="submit" className="btn btn-success">
                Create registry
              </button>
            </div>
          </Col>
        </Row>
      </Form>

      <PreviewRegistryModal
        show={showModal}
        onHide={() => setShowModal(false)}
        registryData={formData}
      />
    </Container>
  );
};

export default StepTwo;