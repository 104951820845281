// RegistryInfo.js
import React from 'react';
import { Card } from 'react-bootstrap';

const RegistryInfo = ({ registryData }) => {
  return (
    <Card className="registry-info">
      <Card.Img
        variant="top"
        src={registryData.photo}
        alt="Child"
        style={{ minWidth: '400px', minHeight: '300px' }}
      />
      <Card.Body>
        <Card.Title>{registryData.registryName}</Card.Title>
        <Card.Text>{registryData.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default RegistryInfo;