// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
    <footer className="bg-dark text-light mt-5">
      <Container>
        <Row className="py-3">
              <Col>
                <h5>TrustFund Baby</h5>
              </Col>
              <Col>
                  <Link to="/terms-and-conditions" className="text-light text-decoration-none">Terms</Link> and <Link to="/privacy-policy" className="text-light text-decoration-none">Privacy Policy</Link>
              </Col>
              <Col>
                  &copy; {new Date().getFullYear()} Coinsave Inc.
              </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;