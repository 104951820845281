// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import SupabaseLogin from './SupabaseLogin';

// Import local components
import HomePage from './HomePage';
import Footer from './Footer';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';


/**
 * ScrollToTop component ensures that the page scrolls to the top on route changes.
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  // State to manage user-specific data
  const [userState, setUserState] = useState({
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    registryName: '',
    occasion: 'Birthday',
    description: '',
    photo: null,
  });


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand-logo" style={{ fontFamily: 'Arial, sans-serif', fontSize: '24px', fontWeight: 'bold' }}>
            <span style={{ color: '#00a86b' }}>TrustFund</span><span style={{ color: '#4d4d4d' }}>Baby</span>
          </Navbar.Brand>
          <Nav className="ms-auto">
            <SupabaseLogin userState={userState} setUserState={setUserState} />
          </Nav>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<HomePage userState={userState} setUserState={setUserState} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/create-registry" element={<StepOne formData={formData} setFormData={setFormData} setUserState={setUserState} />} />
        <Route path="/create-registry-step-two" element={<StepTwo formData={formData} setFormData={setFormData} userState={userState} setUserState={setUserState} />} />
        <Route path="/create-registry-step-three" element={<StepThree formData={formData} setFormData={setFormData} setUserState={setUserState} />} /> 
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
