// GiftForm.js
import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';

const GiftForm = () => {
  const [giftAmount, setGiftAmount] = useState('100.00');

  const handleAmountChange = (event) => {
    setGiftAmount(event.target.value);
  };

  return (
    <Card className="gift-form">
      <Card.Body>
        <Row>
          <Col>
            <Form.Control
              type="number"
              value={giftAmount}
              onChange={handleAmountChange}
              placeholder="Enter gift amount"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="gift-options">
              <Button variant="outline-secondary">$25</Button>{' '}
              <Button variant="outline-secondary">$50</Button>{' '}
              <Button variant="outline-secondary">$100</Button>{' '}
              <Button variant="outline-secondary">$200</Button>{' '}
              <Button variant="outline-secondary">$300</Button>{' '}
              <Button variant="outline-secondary">$500</Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="success" block>
              Send Bitcoin
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="gift-info">
              <p>56 people sent gifts</p>
              <ul className="gift-list">
                <li>
                  <img src="path/to/annette-black.png" alt="Annette Black" />
                  Annette Black
                </li>
                <li>Anonymous</li>
                <li>
                  <img src="path/to/jacob-jones.png" alt="Jacob Jones" />
                  Jacob Jones
                </li>
                <li>
                  <img src="path/to/cody-fisher.png" alt="Cody Fisher" />
                  Cody Fisher
                </li>
                <li>Anonymous</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GiftForm;