// PromotionalSection.js
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const PromotionalSection = () => {
  return (
    <Card className="promotional-section">
      <Card.Body>
        <Card.Title>Your simple, trusted home for giving</Card.Title>
        <Card.Text>
          Send gifts to your loved ones that will grow with them and make their future a better one.
        </Card.Text>
        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src="path/to/simple-icon.png" />
              <Card.Body>
                <Card.Title>Simple</Card.Title>
                <Card.Text>Send a gift to someone you love in seconds</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img variant="top" src="path/to/secure-icon.png" />
              <Card.Body>
                <Card.Title>Secure</Card.Title>
                <Card.Text>Your gift is protected by the TrustFundBaby Guarantee</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img variant="top" src="path/to/smart-icon.png" />
              <Card.Body>
                <Card.Title>Smart</Card.Title>
                <Card.Text>Your gift will grow even more meaningful over time</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PromotionalSection;