import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, ProgressBar } from 'react-bootstrap';
import './StepOne.css';

const StepOne = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [smsSent, setSmsSent] = useState(false);
  const [smsCode, setSmsCode] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Auto-prepend +1 for phone numbers if not already present
    if (name === 'phoneNumber' && !value.startsWith('+1')) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: '+1' + value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleContinue = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      form.classList.add('was-validated');
    } else {
      if (smsSent) {
        // Verify the SMS code
        const verifyResponse = await fetch('http://localhost:3001/verify-sms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber: formData.phoneNumber, code: smsCode }),
        });
        if (verifyResponse.ok) {
          navigate('/create-registry-step-two');
        } else {
          alert('Failed to verify SMS. Please check your code and try again.');
        }
      } else {
        // Send the SMS
        const smsResponse = await fetch('http://localhost:3001/send-sms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber: formData.phoneNumber }),
        });
        if (smsResponse.ok) {
          setSmsSent(true);
        } else {
          alert('Failed to send SMS. Please try again.');
        }
      }
    }
  };

  return (
    <Container className="wizard-container">
      <Row className="step-indicator">
        <Col>
          <div className="step-number">Step 1 of 3</div>
          <ProgressBar now={33} className="step-progress" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="my-4 registry-title">Create a New Registry</h2>
        </Col>
      </Row>
      <Form noValidate onSubmit={handleContinue}>
        <Row className="mb-3">
          <Form.Group as={Col} md={6} controlId="validationFirstName">
            <Form.Label>Parent's first name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Enter first name"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="validationLastName">
            <Form.Label>Parent's last name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Enter last name"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="validationEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={formData.phoneNumber || ''}
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        {smsSent && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationSmsCode">
              <Form.Label>Enter SMS Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="SMS Code"
                value={smsCode}
                onChange={(e) => setSmsCode(e.target.value)}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please enter the SMS code sent to your phone.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        )}
        <Row className="mt-4">
          <Col className="d-flex justify-content-between">
            <button type="button" className="btn btn-outline-success" onClick={handleBack}>
              Back
            </button>
            <button type="submit" className="btn btn-success">
              {smsSent ? 'Verify and Continue' : 'Send SMS and Continue'}
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepOne;
