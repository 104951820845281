// StepThree.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, ProgressBar, Button } from 'react-bootstrap';
import './StepOne.css';

const StepThree = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [numInvitees, setNumInvitees] = useState(3);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate('/create-registry-step-two'); // Go back to StepTwo
  };

  const handleAddFriend = () => {
    setNumInvitees((prevNumInvitees) => prevNumInvitees + 1);
  };

  const handleShare = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Here you'd typically handle the share invitation logic
      console.log('Share invitation clicked');
      // You can navigate to a success page or perform any other necessary actions
    }

    form.classList.add('was-validated');
  };

  return (
    <Container className="wizard-container">
      <Row className="step-indicator">
        <Col>
          <div className="step-number">Step 3 of 3</div>
          <ProgressBar now={100} className="step-progress" />
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="my-4 registry-title">Share your registry with family and friends</h2>
        </Col>
      </Row>

      <Form noValidate onSubmit={handleShare}>
        {[...Array(numInvitees)].map((_, index) => (
          <Row key={index} className="mb-3">
            <Col>
              <Form.Group controlId={`inviteeName${index}`}>
                <Form.Label>Invitee's full name</Form.Label>
                <Form.Control
                  type="text"
                  name={`inviteeName${index}`}
                  value={formData[`inviteeName${index}`] || ''}
                  onChange={handleInputChange}
                  placeholder="Alex Smith"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`inviteePhone${index}`}>
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="tel"
                  name={`inviteePhone${index}`}
                  value={formData[`inviteePhone${index}`] || ''}
                  onChange={handleInputChange}
                  placeholder="(212) 555-1234"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a phone number.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`inviteeEmail${index}`}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name={`inviteeEmail${index}`}
                  value={formData[`inviteeEmail${index}`] || ''}
                  onChange={handleInputChange}
                  placeholder="Alex@trustfundbaby.com"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        ))}

        <Row className="mb-3">
          <Col>
            <Button variant="success" onClick={handleAddFriend}>
              Add friends
            </Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="registry-link">
              <span>Share registry</span>
              <div className="link-container">
                <span>coinsave.com/wedding_123</span>
                <Button variant="link" onClick={() => console.log('Copy registry link clicked')}>
                  Copy registry link
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-between">
            <Button variant="outline-success" onClick={handleBack}>
              Back
            </Button>
            <Button variant="success" type="submit">
              Share invitation
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepThree;