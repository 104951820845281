// HomeCarousel.js
import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './HomeCarousel.css';

function HomeCarousel({ navigateToRegistryWizard }) {
  const [randomImages, setRandomImages] = useState([]);

  useEffect(() => {
    const imagesListItem1 = ['baby6.webp'];
    const imagesListItem2 = ['baby9.webp'];
    const imagesListItem3 = ['baby7.webp'];

    const selectedImages = [
      imagesListItem1[Math.floor(Math.random() * imagesListItem1.length)],
      imagesListItem2[Math.floor(Math.random() * imagesListItem2.length)],
      imagesListItem3[Math.floor(Math.random() * imagesListItem3.length)],
    ];

    setRandomImages(selectedImages);
  }, []);

  return (
    <Carousel className="full-width-carousel" controls={false}>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src={randomImages[0]}
            alt="Secure Her Future"
          />
        </div>
        <Carousel.Caption>
          <h1 className="carousel-title">Secure Her Future</h1>
          {/* ... */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src={randomImages[1]}
            alt="Build Savings for Your Children"
          />
        </div>
        <Carousel.Caption>
          <h1 className="carousel-title">Time is his biggest ally in building wealth</h1>
          {/* ... */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src={randomImages[2]}
            alt="Gifts that Grow for Life."
          />
        </div>
        <Carousel.Caption>
          <h1 className="carousel-title">She deserves a gift that grows with her</h1>
          {/* ... */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeCarousel;