// PreviewRegistryModal.js
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import RegistryInfo from './RegistryInfo';
import GiftForm from './GiftForm';
import PromotionalSection from './PromotionalSection';

const PreviewRegistryModal = ({ show, onHide, registryData }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <Row style={{ height: '65%' }}>
          <Col xs={8}>
            <RegistryInfo registryData={registryData} />
          </Col>
          <Col xs={4}>
            <GiftForm />
          </Col>
        </Row>
        <Row style={{ height: '35%' }}>
          <Col>
            <PromotionalSection />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewRegistryModal;