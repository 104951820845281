// HomePage.js
// Import necessary React components and hooks
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Import Bootstrap components and custom CSS
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './HomePage.css';

// Import JSON data and other components used in the page
import cardData from './cardData.json';
import FAQ from './FAQ';
import HowItWorks from './HowItWorks';
import ChatInterface from './ChatInterface';
import HomeCarousel from './HomeCarousel'; // Import the new HomeCarousel component
import { CardBody } from 'react-bootstrap';

function HomePage({ userState, setUserState }) {
  const navigate = useNavigate(); // Hook for navigating programmatically
  const packagesRef = useRef(null); // Reference for the cards section for potential scrolling actions

  const navigateToRegistryWizard = () => {
    navigate('/create-registry');
  };

  return (
    <>
{/* Carousel component */}
      <HomeCarousel navigateToRegistryWizard={navigateToRegistryWizard} />

{/* Main container for content below the carousel */}
      <Container className="mt-4 mb-4">

{/* Marketing section */}
        <div className="marketing-section">
          <h1 className='marketing-title'>Build savings for your child with the help of family and friends</h1>
          <p className="marketing-text">
            Say goodbye to throw-away gifts. Encourage family and friends to help your child prosper by sending cash.
          </p>
        </div>

{/* Types of Registries */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" ref={packagesRef}>
          {cardData.map((card, index) => (
            <div key={index} className="col">
              <Card className="shadow-sm">
                <Card.Img variant="top" src={card.image} />
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.problem}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>

{/* CTA to create registry */}
        {/* <div className="registries-section">
          <Button variant="success" size="sm" className="carousel-button" onClick={navigateToRegistryWizard}>
            Create a Registry
          </Button>
        </div> */}

{/* How it works */}
<div className="howitworks-section"> <HowItWorks /> </div>

        {/* Chat interface for beta testing feedback */}
        <Card style={{ border: 0, marginTop: '20px' }}>
          <Card.Body>
            {/* <h2 className="text-center">Join the Beta!</h2> */}
            <ChatInterface />
          </Card.Body>
        </Card>

        {/* FAQ component commented out, may be toggled for visibility */}
        <FAQ />

      </Container>
    </>
  );
}

export default HomePage;
