// LoginModal.js
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function LoginModal({ show, onHide, email, setEmail, error, loading, handleMagicLinkSignIn, emailSent }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleMagicLinkSignIn();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton style={{ backgroundColor: '#e8f5e9', padding: '0.5rem 1rem' }}>
        <Modal.Title>Login or Signup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {emailSent ? (
          <div className="text-center">
            <p>An email has been sent, please check your inbox.</p>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Please enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="sm"
                className="mb-3"
              />
              {error && <Form.Text style={{ color: 'red' }}>{error}</Form.Text>}
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="outline-secondary" onClick={onHide} size="sm">
                Cancel
              </Button>
              <Button type="submit" variant="success" disabled={loading} size="sm">
                {loading ? "Sending..." : "Send Magic Link"}
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;