// SupabaseLogin.js
import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Button from 'react-bootstrap/Button';
import LoginModal from './LoginModal';

const supabase = createClient('https://wrqtxwalspexjzybqkss.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndycXR4d2Fsc3BleGp6eWJxa3NzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUxMTU3NTIsImV4cCI6MjAzMDY5MTc1Mn0.8vkbux4ptJe2TdK_7gYQDdx2IiUnQQtCiXbotiaDSLI');

function SupabaseLogin({ userState, setUserState }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUserState({ session });
    };

    fetchSession();

    const { data: { subscription }, } = supabase.auth.onAuthStateChange((_event, session) => {
      setUserState({ session });
    });

    return () => subscription.unsubscribe();
  }, [setUserState]);

  const handleMagicLinkSignIn = async () => {
    setLoading(true);
    setError(null);

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: window.location.origin,
      },
    });

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {
      setEmailSent(true);
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUserState({ session: null });
  };

  return (
    <>
      {userState.session ? (
        <Button variant="success" className="navbar-button" onClick={handleLogout}>Logout</Button>
      ) : (
        <Button variant="success" className="navbar-button" onClick={() => setIsModalOpen(true)}>Login</Button>
      )}
      <LoginModal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
          setEmailSent(false);
        }}
        email={email}
        setEmail={setEmail}
        error={error}
        loading={loading}
        handleMagicLinkSignIn={handleMagicLinkSignIn}
        emailSent={emailSent}
      />
    </>
  );
}

export default SupabaseLogin;